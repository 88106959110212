import { useEffect } from 'react';
import { useLoader } from '@react-three/fiber';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export function OBJModel({ url }) {
  const obj = useLoader(OBJLoader, url);
  return <primitive object={obj} scale={[0.01, 0.01, 0.01]} />;
}

export function FBXModel({ url }) {
  const fbx = useLoader(FBXLoader, url);
  return <primitive object={fbx} scale={[0.01, 0.01, 0.01]} />;
}

export function GLTFModel({ url }) {
  const gltf = useLoader(GLTFLoader, url);
  return <primitive object={gltf.scene} scale={[0.01, 0.01, 0.01]} />;
}

export function STLModel({ url }) {
  const geometry = useLoader(STLLoader, url);
  return (
    <mesh geometry={geometry} scale={[0.01, 0.01, 0.01]} position={[0, 0, 0]}>
      <meshBasicMaterial color="black" wireframe={true} />
    </mesh>
  );
}

export function Model({ url }) {
  useEffect(() => {
    return () => {
      if (url && url.startsWith('blob:')) {
        URL.revokeObjectURL(url);
      }
    };
  }, [url]);

  return <STLModel url={url} />;
}
