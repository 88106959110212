import { useState } from 'react';
import { validateFileType } from '../utils/fileHelpers';

export function useFileUpload() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [modelUrl, setModelUrl] = useState(null);

  const handleFileSelect = (event) => {
    try {
      const file = event.target.files[0];
      validateFileType(file, '.aasx');
      setSelectedFile(file);
    } catch (error) {
      alert(error.message);
      event.target.value = null;
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file first');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await fetch('https://test-api.itsmyplanetary.me/convert', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Upload failed');
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setModelUrl(url);

    } catch (error) {
      alert(error.message || 'Error occurred during file upload');
    }
  };

  return {
    selectedFile,
    modelUrl,
    handleFileSelect,
    handleUpload
  };
}
