export function ObjectControls({ onAddObject }) {
  const objects = ['box', 'sphere', 'cylinder', 'cone'];
  
  return (
    <div className="border-b-2 border-gray-300 pb-4 mb-4">
      <h3 className="mb-4">Add 3D Objects</h3>
      {objects.map(type => (
        <button
          key={type}
          onClick={() => onAddObject(type)}
          className="w-full mb-2 p-2 border-2 border-black rounded"
        >
          Add {type.charAt(0).toUpperCase() + type.slice(1)}
        </button>
      ))}
    </div>
  );
}
