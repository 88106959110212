import { ObjectControls } from './ObjectControls';
import { FileUpload } from './FileUpload';

export function ControlPanel({ onAddObject, selectedFile, onFileSelect, onUpload }) {
  return (
    <div style={{
      position: 'absolute',
      top: '20px',
      left: '20px',
      zIndex: 1000,
      display: 'flex',
      gap: '10px',
      flexDirection: 'column',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      padding: '15px',
      borderRadius: '8px',
      boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
    }}>
      <ObjectControls onAddObject={onAddObject} />
      <FileUpload
        selectedFile={selectedFile}
        onFileSelect={onFileSelect}
        onUpload={onUpload}
      />
    </div>
  );
}
