import { OrbitControls } from '@react-three/drei';
import { Lighting } from './Lighting';
import { Model } from '../models/Model';
import { Box } from '../primitives/Box';
import { Sphere } from '../primitives/Sphere';
import { Cylinder } from '../primitives/Cylinder';
import { Cone } from '../primitives/Cone';

const componentMap = {
  box: Box,
  sphere: Sphere,
  cylinder: Cylinder,
  cone: Cone
};

export function Scene({ objects, modelUrl }) {
  return (
    <>
      <color attach="background" args={['#f0f0f0']} />
      <OrbitControls makeDefault />
      <Lighting />
      {objects.map((obj, index) => {
        const Component = componentMap[obj.type];
        return Component ? <Component key={index} position={obj.position} /> : null;
      })}
      {modelUrl && <Model url={modelUrl} />}
    </>
  );
}
