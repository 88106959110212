import { useState } from 'react';
import { Canvas3D } from './components/Canvas3D';
import { Scene } from './components/scene/Scene';
import { ControlPanel } from './components/ui/ControlPanel';
import { useFileUpload } from './hooks/useFileUpload';
import { generateRandomPosition } from './utils/positionHelpers';

export default function App() {
  const [objects, setObjects] = useState([]);
  const { selectedFile, modelUrl, handleFileSelect, handleUpload } = useFileUpload();

  const handleAddObject = (type) => {
    const position = generateRandomPosition();
    setObjects(prev => [...prev, { type, position: [position.x, position.y, position.z] }]);
  };

  return (
    <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
      <Canvas3D>
        <Scene objects={objects} modelUrl={modelUrl} />
      </Canvas3D>
      <ControlPanel
        onAddObject={handleAddObject}
        selectedFile={selectedFile}
        onFileSelect={handleFileSelect}
        onUpload={handleUpload}
      />
    </div>
  );
}
