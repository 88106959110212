import { useRef } from 'react';

export function FileUpload({ selectedFile, onFileSelect, onUpload }) {
  const fileInputRef = useRef(null);
  return (
    <div>
      <h3 className="mb-4">AASX File Upload</h3>
      <input
        type="file"
        accept=".aasx"
        ref={fileInputRef}
        className="hidden"
        onChange={onFileSelect}
      />
      <button
        onClick={() => fileInputRef.current?.click()}
        className="w-full mb-4 p-2 border-2 border-black rounded"
      >
        Select AASX File
      </button>

      {selectedFile && (
        <div className="mb-4 break-all text-sm">
          Selected file: {selectedFile.name}
        </div>
      )}

      <button
        onClick={onUpload}
        disabled={!selectedFile}
        className={`w-full p-2 rounded ${
          selectedFile ? 'bg-green-500' : 'bg-gray-400'
        } text-white`}
      >
        Upload AASX File
      </button>
    </div>
  );
}
